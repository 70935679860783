<template>
  <div class="main_box">
    <div>
      <el-card v-if="currentPackage.state && !['10', '20'].includes(currentPackage.state)" class="card_box" shadow="always">
        <div slot="header" class="clearfix">
          <span>版本号：{{currentPackage.version}}</span>
          <div class="carry-on-btn">
            <Tag size="mini" class="error_tip" v-if="currentPackage.state === '20'" type="danger">
              {{currentPackage.message}}
            </Tag>
            <span class="pack_time">
              <span>耗时：</span>
              <span>{{ packTime }}</span>
            </span>
            <el-button v-rights="'athena:package:execute'" v-if="!['00', '30', '20'].includes(currentPackage.state)" @click.native="checkPackData" type="primary" size="small"><GIcon icon="icon-qidong"></GIcon>
              生成离线数据包</el-button>
          </div>
        </div>
        <div v-if="currentPackage.state !== '20'">
          <el-steps :active="currentPackage.active">
            <el-step title="排队中" :description="currentPackage.createTimeStr">
              <i class="el-icon-refresh start" v-if="currentPackage.state==='00'" slot="icon" />
            </el-step>
            <el-step
              title="打包中"
              :description="currentPackage.startTimeStr"
              :status="currentPackage.state==='20'?'error':''"
            >
              <i class="el-icon-refresh start" v-if="currentPackage.state==='30'" slot="icon" />
            </el-step>
            <el-step
              v-if="currentPackage.state === '20'"
              title="打包失败"
              status="error"
              :description="currentPackage.finishTimeStr"
            />
            <el-step v-else title="打包完成" :description="currentPackage.finishTimeStr" />
          </el-steps>
        </div>
        <div v-rights="'athena:package:execute'" v-else class="re-package" @click="checkPackData">
          <GIcon icon="icon-qidong" />
          生成离线数据包
        </div>
      </el-card>
      <el-card v-else class="normal-data" :class="{'no-auth-add': !userRights.get('athena:package:execute')}" @click.native="checkPackData">
        <GIcon icon="icon-qidong" />
        生成离线数据包
      </el-card>
    </div>
    <div class="table_box">
      <Table class="license-table" rowKey="id" :columns="columns" :data-source="tableData" :pagination="false" bordered :scroll="{ y: 'calc(100% - 39px)' }">
        <template slot="state" slot-scope="text, record">
          <Tag effect="dark" :type="packageTag[record.state].type" size="mini">{{ packageTag[record.state].text }}</Tag>
        </template>
        <template slot="sourceUrl" slot-scope="text, record">
          <GIcon v-if="record.sourceUrl" icon="icon-yasuo" style="font-size: 14px;margin-right:4px;vertical-align: middle" />
          <Link type="primary" :href="record.sourceUrl" style="font-weight: normal">{{ record.sourceUrl }}</Link>
        </template>
      </Table>
    </div>

    <CreatePackage ref="createPackage" :equipType="equipType" @change="getCurrentPackage"></CreatePackage>

    <GTablePagination :total="total" @changePage="pageInfo => getTableData(pageInfo)" />

  </div>
</template>

<script>
  import { Card, Steps, Step, Tag, Link } from 'element-ui'
  import CreatePackage from '../components/CreatePackage'
  import { Table } from 'ant-design-vue'
  import GTablePagination from '@/components/GTablePagination'
  import moment from 'moment'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Customer',
    components: {
      GTablePagination,
      'el-card': Card,
      'el-steps': Steps,
      'el-step': Step,
      Tag,
      Link,
      CreatePackage,
      Table
    },
    data () {
      return {
        columns: [
          {
            title: '序号',
            key: 'index',
            customRender: (text, record, index) => index + 1,
            width: 60,
            align: 'center'
          },
          { title: '历史版本', dataIndex: 'version', width: 120 },
          { title: '打包信息', dataIndex: 'message', ellipsis: true },
          { title: '打包结果', dataIndex: 'state', width: 80, scopedSlots: { customRender: 'state' } },
          { title: '数据包地址', dataIndex: 'sourceUrl', width:420, scopedSlots: { customRender: 'sourceUrl' } },
          { title: '操作人', dataIndex: 'operatorName', width: 100 },
          { title: '开始时间', dataIndex: 'startTimeStr', width: 160 },
          { title: '结束时间', dataIndex: 'finishTimeStr', width: 160 },
          { title: '耗时', dataIndex: 'runTimeStr', width: 100 },
        ],
        packageTag: {
          '00': { type: 'warning', text: '排队中' },
          '10': { type: 'success', text: '成功' },
          '20': { type: 'danger', text: '失败' },
          '30': { type: 'warning', text: '打包中' },
        },
        equipType: 'pc',
        tableData: [],
        currentPackage: {},
        tableLoad: false,
        pageSize: 20,
        page: 1,
        total: 0,
        packTime: '正在计算中...',
        packTimeTimeOut: null
      }
    },
    computed: {
      ...mapGetters(['userRights'])
    },
    created () {
      this.getTableData({ page: 1, pageSize: this.pageSize })
      this.getCurrentPackage()
    },
    methods: {
      async getTableData (pageInfo) {
        this.pageSize = pageInfo.pageSize
        this.page = pageInfo.page
        this.tableLoad = true
        const response = await this.$axios.get('/athena/data/history', {
          equipType: this.equipType,
          size: this.size,
          page: this.page
        })
        this.tableLoad = false
        this.tableData = response.data.list
        this.total = response.data.total
      },
      async getCurrentPackage () {
        const response = await this.$axios.get('/athena/data/current', { equipType: this.equipType })
        let active
        if (response.data.state === '00') {
          active = 1
          setTimeout(() => {
            this.getCurrentPackage()
            this.getTableData({ page: this.page, pageSize: this.pageSize })
          }, 2000)
        } else if (response.data.state === '10') {
          active = 3
        } else if (response.data.state === '20') {
          active = 3
        } else if (response.data.state === '30') {
          active = 2
          setTimeout(() => {
            this.getCurrentPackage()
            this.getTableData({ page: this.page, pageSize: this.pageSize })
          }, 2000)
        }
        this.currentPackage = {
          ...response.data,
          active
        }
        this.startPackTime()
      },
      startPackTime() {
        if (['10', '20'].includes(this.currentPackage.state)) {
          this.packTime = this.computedPackTimeStr()
          clearInterval(this.packTimeTimeOut)
          this.packTimeTimeOut = null
        } else {
          this.packTimeTimeOut = setInterval(() => {
            this.packTime = this.computedPackTimeStr()
          }, 1000)
        }
      },
      computedPackTimeStr () {
        let m1 = moment(this.currentPackage.createTime), m2 = moment(moment(new Date()).format('YYYY/MM/DD HH:mm:ss'));
        let duration = moment.duration(m2.diff(m1));
        let { _data } = duration;
        return `${_data.minutes}分${_data.seconds}秒`
      },
      checkPackData () {
        const self = this
        this.$antConfirm({
          title: '生成离线数据包',
          content: '是否确定生成离线数据包？',
          width: '500px',
          okText: '保存',
          okType: 'primary',
          cancelText: '取消',
          onOk() {
            self.packTimeTimeOut = ``
            self.$refs.createPackage.handlePackAdd()
          },
          onCancel() {}
        })
      },
      change () {
        this.getTableData({ page: 1, pageSize: this.pageSize })
        this.getCurrentPackage()
      }
    }
  }
</script>

<style scoped lang="scss">
  .main_box {
    height: 100%;
    padding: 10px;
    position: relative;
    .page-container{
      position: absolute;
      left: 0;
      bottom: 0;
      background: #fff;
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      border-top: 1px #ccc solid;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 2;
      .el-pagination{
        height: 40px;
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.65);
        ::v-deep{
          .el-pager{
            height: 30px;
          }
        }
      }
    }
    h1 {
      font-size: 23px;
      font-weight: 400;
      padding: 9px 0 4px;
      line-height: 29px;
      margin-bottom: 20px;
      .pack-btn {
        float: right;
        margin-right: 20px;
      }
    }
    .title_text {
      font-size: 20px;
      margin-bottom: 10px;
      .equip_type {
        margin-left: 20px;
      }
    }
    .card_box {
      font-size: 16px;
      color: #303133;
      .clearfix{
        display: flex;
        align-items: center;
      }
      .error_tip {
      }
      .pack_time{
        margin-right: 24px;
        span{
          &:first-child{
            vertical-align: text-bottom;
          }
          &:last-child{
            color: rgba(0,0,0,.85);
            font-size: 24px;
          }
        }
      }
      .carry-on-btn{
        margin-left: auto;
      }
      .el-icon-refresh {
        font-size: 20px;
        &.start {
          animation: rotating 1s linear infinite;
        }
      }
    }
    .table_box {
      margin-top: 10px;
      height: calc(100% - 239px);
      overflow-y: auto;
      border-radius: 4px;
      ::v-deep{
        .el-table{
          border-radius: 4px;
          height: 100%;
        }
        .is-scrolling-none{
          height: calc(100% - 36px);
        }
        .el-table__empty-block{
          height: 100%;
        }
      }
    }
  }
  ::v-deep {
    .el-dialog {
      border-radius: 4px !important;
      .el-dialog__title {
        font-size: 14px;
      }
      .el-dialog__header {
        padding: 0 10px;
        line-height: 30px;
        border-bottom: 1px solid #EAEBF0;
        font-size: 14px;
      }
      .el-dialog__headerbtn {
        top: 4px;
        right: 6px
      }
      .el-dialog__body {
        padding: 20px;
        background: #FBFCFD;

      }
      .el-dialog__footer {
        border-top: 1px solid #EAEBF0;
        padding: 12px;
        text-align: center;
      }
    }
    .normal-data{
      height: 181px;
      box-sizing: border-box;
      text-align: center;
      font-size: 16px;
      padding: 24px;
      color: #409EFF;
      .el-card__body{
        font-size: 40px;
        padding: 0;
        border: 2px #409EFF dashed;
        background: rgba(255, 255, 255, .8);
        letter-spacing: 2px;
        height: 100%;
        border-radius: 4px;
        cursor: pointer;
        transition: all .3s;
        line-height: 131px;
        &:hover{
          box-shadow: $cardShadow;
        }
      }
    }
    .no-auth-add{
      .el-card__body{
        color: #909399;
        border-color: #909399;
      }
    }
  }
  .license-table{
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    height: 100%;
    ::v-deep{
      .ant-spin-nested-loading{
        width: 100%;
        height: 100%;
        .ant-spin-container{
          height: 100%;
          .ant-table{
            height: 100%;
            .ant-table-content{
              height: 100%;
              .ant-table-scroll{
                height: 100%;
                .ant-table-thead{
                  tr{
                    th{
                      padding: 8px;
                    }
                  }
                }
                .ant-table-tbody{
                  tr{
                    td{
                      padding: 8px;
                    }
                  }
                }
                .ant-table-body{
                  margin: 0;
                }
              }
            }
          }
        }
      }
      .ant-table-placeholder{
        border-bottom: none;
      }
      .ant-table-column-title{
        color: #475669;
        font-size: 12px;
        border-color: #EBEEF5;
      }
    }
  }
  .re-package{
    height: 78px;
    line-height: 78px;
    text-align: center;
    font-size: 40px;
    padding: 0;
    border: 2px #409EFF dashed;
    background: rgba(255, 255, 255, .8);
    letter-spacing: 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: all .3s;
    color: #409EFF;
    &:hover{
      box-shadow: $cardShadow;
    }
  }
</style>
